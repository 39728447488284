import * as Types from '../types/graphql.gen';

import { DocumentTypeDecoration } from '@graphql-typed-document-node/core';
export type FeatureGloballyFlippedSubscriptionVariables = Types.Exact<{ [key: string]: never; }>;


export type FeatureGloballyFlippedSubscription = { __typename?: 'Subscription', featureFlipped: { __typename?: 'FeatureFlippedPayload', feature: string, enabled: boolean } };

export type FeatureToggledSubscriptionVariables = Types.Exact<{
  accountId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  userId?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type FeatureToggledSubscription = { __typename?: 'Subscription', featureToggled: { __typename?: 'FeatureToggledPayload', feature: string, enabled: boolean } };

export declare const FeatureGloballyFlipped: import("graphql").DocumentNode;
export declare const FeatureToggled: import("graphql").DocumentNode;
export class TypedDocumentString<TResult, TVariables>
  extends String
  implements DocumentTypeDecoration<TResult, TVariables>
{
  __apiType?: DocumentTypeDecoration<TResult, TVariables>['__apiType'];

  constructor(private value: string, public __meta__?: Record<string, any>) {
    super(value);
  }

  toString(): string & DocumentTypeDecoration<TResult, TVariables> {
    return this.value;
  }
}

export const FeatureGloballyFlippedDocument = new TypedDocumentString(`
    subscription FeatureGloballyFlipped {
  featureFlipped {
    feature
    enabled
  }
}
    `) as unknown as TypedDocumentString<FeatureGloballyFlippedSubscription, FeatureGloballyFlippedSubscriptionVariables>;
export const FeatureToggledDocument = new TypedDocumentString(`
    subscription FeatureToggled($accountId: Int, $userId: Int) {
  featureToggled(appUserId: $userId, accountId: $accountId) {
    feature
    enabled
  }
}
    `) as unknown as TypedDocumentString<FeatureToggledSubscription, FeatureToggledSubscriptionVariables>;